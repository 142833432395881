import * as React from 'react';
import dynamic from 'next/dynamic';

const TextBlock = dynamic(() => import('../components/Modules/TextBlock'));
const Cards = dynamic(() => import('../components/Modules/Cards'));
const Video = dynamic(() => import('../components/Modules/Video'));
const Image = dynamic(() => import('../components/Modules/Image'));
const Quotes = dynamic(() => import('../components/Modules/Quotes'));
const Partners = dynamic(() => import('../components/Modules/Partners'));
const Statistics = dynamic(() => import('../components/Modules/Statistics'));
const Banner = dynamic(() => import('../components/Modules/Banner'));
const News = dynamic(() => import('../components/Modules/News'));
const ProductTypes = dynamic(() => import('../components/Modules/Product/Types'));
const ProductDisplay = dynamic(() => import('../components/Modules/Product/Display'));
const NumberingList = dynamic(() => import('../components/Modules/NumberingList'));
const Form = dynamic(() => import('../components/Modules/Form'));
const Downloads = dynamic(() => import('../components/Modules/Downloads'));
const SmallImages = dynamic(() => import('../components/Modules/SmallImages'));
const Contact = dynamic(() => import('../components/Modules/Contact'));
const Links = dynamic(() => import('../components/Modules/Links'));
const Table = dynamic(() => import('../components/Modules/Table'));

export default [
  {
    id: 0,
    module: 'TextBlock',
    component: <TextBlock />,
  },
  {
    id: 1,
    module: 'Cards',
    component: <Cards />,
    options: {
      sliderThreshold: 4,
    },
  },
  {
    id: 2,
    module: 'Video',
    component: <Video />,
  },
  {
    id: 3,
    module: 'Image',
    component: <Image />,
  },
  {
    id: 4,
    module: 'Quotes',
    component: <Quotes />,
  },
  {
    id: 5,
    module: 'Partners',
    component: <Partners />,
  },
  {
    id: 6,
    module: 'Statistics',
    component: <Statistics />,
  },
  {
    id: 7,
    module: 'Banner',
    component: <Banner />,
  },
  {
    id: 8,
    module: 'BannerLanding',
    component: <Banner type="landing" />,
  },
  {
    id: 9,
    module: 'News',
    component: <News />,
  },
  {
    id: 10,
    module: 'ProductTypes',
    component: <ProductTypes />,
  },
  {
    id: 11,
    module: 'ProductDisplay',
    component: <ProductDisplay />,
  },
  {
    id: 12,
    module: 'NumberingList',
    component: <NumberingList />,
  },
  {
    id: 13,
    module: 'Form',
    component: <Form />,
  },
  {
    id: 14,
    module: 'Downloads',
    component: <Downloads />,
  },
  {
    id: 15,
    module: 'SmallImages',
    component: <SmallImages />,
  },
  {
    id: 16,
    module: 'Contact',
    component: <Contact />,
  },
  {
    id: 17,
    module: 'Links',
    component: <Links />,
  },
  {
    id: 18,
    module: 'Table',
    component: <Table />,
  },
];
